import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Anchor from "@ui/anchor";
import { ItemType } from "@utils/types";
import { ContactInfoWrapper } from "./style";

const ContactArea = () => {
  return (
    <ContactInfoWrapper>
      <Container>
        <Row>
          <Col lg={6} mb="30px">
            <Heading as="h5" mb="20px">
              <Anchor mb="7px" path="#">
                <i className="far fa-envelope" />
                contacto@dkcateringyeventos.com
              </Anchor>
            </Heading>
          </Col>
          <Col lg={6} mb="30px">
            <Heading as="h5" mb="20px">
              <Anchor mb="7px" path="#">
                <i className="fab fa-whatsapp" />
                +51 981 182 525
              </Anchor>
            </Heading>
          </Col>
        </Row>
      </Container>
    </ContactInfoWrapper>
  );
};

ContactArea.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
  }),
};

export default ContactArea;
