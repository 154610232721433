import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import ContactForm from "@components/forms/contact-form";
import { HeadingType, TextType } from "@utils/types";
import { StyledSection, LeftBox, StyledHeading, StyledDesc } from "./style";

const ContactArea = () => {
  return (
    <StyledSection>
      <Container>
        <Row alignItems="center">
          <Col lg={6}>
            <LeftBox>
              <StyledHeading>¿Tienes preguntas?</StyledHeading>
              <StyledDesc>
                ¿Necesitas un catering para tu evento? ¡Estupendo! Por lo
                general, respondemos dentro de las 24-48 horas.
              </StyledDesc>
            </LeftBox>
          </Col>
          <Col lg={6}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
};

ContactArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
  }),
};

export default ContactArea;
