import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@ui/page-header/layout-02";
import ContactArea from "@containers/contacto/contacto-area";
import ContactInfoArea from "@containers/contacto/contacto-info-area";
import CtaArea from "@ui/cta/layout-04";

const ContactoPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Contacto" />
      <Header transparent />
      <main className="site-wrapper-reveal">
        <PageHeader title="Contactáctanos" />
        <ContactArea />
        <ContactInfoArea />
        <CtaArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default ContactoPage;
